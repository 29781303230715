// Footer.js
import { connect } from 'react-redux';

import {
  SUPPORTED_LANGUAGES,
  DMCA_ID,
  SWAG_SOCIAL_MEDIA_LINKS,
  SWAG_ABOUT_LINKS,
  SWAG_SUPPORT_LINKS,
  SWAG_WORK_LINKS,
  SWAG_DISCOVER_LINKS,
  PUBLIC_LOGO_URL_PATH,
  RTA_URL,
} from '../RemoteConfigKeys.js';

import Footer from '../component/Footer.jsx';
import withRouter from '../component/WithRouter.jsx';

import updateLanguage from '../action/updateLanguage.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const mapStateToProps = state => {
  return {
    publicLogoUrlPath: getRemoteConfigData(state, PUBLIC_LOGO_URL_PATH),
    supportedLanguages: getRemoteConfigData(state, SUPPORTED_LANGUAGES),
    dmcaId: getRemoteConfigData(state, DMCA_ID),
    socialMediaLinks: getRemoteConfigData(state, SWAG_SOCIAL_MEDIA_LINKS),
    aboutLinks: getRemoteConfigData(state, SWAG_ABOUT_LINKS),
    supportLinks: getRemoteConfigData(state, SWAG_SUPPORT_LINKS),
    workLinks: getRemoteConfigData(state, SWAG_WORK_LINKS),
    discoverLinks: getRemoteConfigData(state, SWAG_DISCOVER_LINKS),
    rtaUrl: getRemoteConfigData(state, RTA_URL),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: ({ language }) => dispatch(updateLanguage({ language })),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
